(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('emsApp')
        .constant('VERSION', "2.1.3")
        .constant('DEBUG_INFO_ENABLED', true)
        .constant('BUILD_TIMESTAMP', "")
        .constant('AUTHORIZE_LINKY_URL', "https://mon-compte-particulier.enedis.fr")
        .constant('TOKEN_LINKY_URL', "https://gw.prd.api.enedis.fr")
        .constant('CLIENT_ID', "216635c8-95ea-4313-ba2d-8988c1179e68")
        .constant('CLIENT_SECRET', "546faa1d-9021-44f4-8ca1-0ca427bb8f2e")
;
})();
